import React from 'react'

export default function ToAvoidWomenIcon({ className }) {
    return (
        <svg className={className} viewBox="0 0 332 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="166" cy="50" r="49.5" fill="white" stroke="#68451C" />
            <path d="M124 8L208.853 92.8528" stroke="#BD3F3F" stroke-width="4" stroke-linecap="round" />
            <path d="M208.853 8L124 92.8528" stroke="#BD3F3F" stroke-width="4" stroke-linecap="round" />
            <circle cx="282" cy="50" r="49.5" fill="#D7BEE1" stroke="#68451C" />
            <path d="M240 8L324.853 92.8528" stroke="#BD3F3F" stroke-width="4" stroke-linecap="round" />
            <path d="M324.853 8L240 92.8528" stroke="#BD3F3F" stroke-width="4" stroke-linecap="round" />
            <path d="M50 100C63.0846 100 74.9615 94.9308 83.8769 86.7C93.7653 77.5654 100 64.5269 100 50C100 22.3846 77.6154 0 50 0C22.3846 0 0 22.3846 0 50C0 64.5269 6.23468 77.5654 16.1231 86.7C25.0385 94.9308 36.9154 100 50 100ZM3.84615 50C3.84615 24.55 24.55 3.84615 50 3.84615C75.45 3.84615 96.1538 24.55 96.1538 50C96.1538 63.7038 90.1154 76 80.5962 84.4577C75.8654 82.3615 66.8115 80.5654 61.7692 79.7039C60.0115 79.4346 58.8769 79.2923 58.8769 79.2923C58.8769 79.2923 57.2191 75.6423 57.246 71.5654C57.2537 70.8154 57.3 70.0539 57.4423 69.2923C82.0423 77.7423 79.5807 60.3923 79.5807 60.3923C79.5807 60.3923 76.5576 66.3115 73.5999 60.3923C72.0192 57.2384 73.6 49.7077 70.6346 37.8731C67.4423 25.0808 57.7385 20.1269 50 20.1269C42.2615 20.1269 32.5577 25.0808 29.3654 37.8731C26.4 49.7077 27.9808 57.2384 26.4001 60.3923C23.4424 66.3115 20.4193 60.3923 20.4193 60.3923C20.4193 60.3923 17.9577 77.7462 42.5577 69.2923C42.7 70.0539 42.7463 70.8154 42.754 71.5654C42.7809 75.6423 41.1231 79.2923 41.1231 79.2923C41.1231 79.2923 39.9884 79.4346 38.2345 79.7039C33.1884 80.5693 24.1384 82.3615 19.4038 84.4577C9.88455 76 3.84615 63.7038 3.84615 50Z" fill="black" />
        </svg>
    )
}

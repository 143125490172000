import React from 'react'
import Divider from './divider'
import { IoMenu, IoClose } from "react-icons/io5"
import Swal from 'sweetalert2';

export default function Header() {
    const links = [
        {
            text: 'Info',
            href: '#info',
        },
        {
            text: 'Partecipazione',
            href: '#partecipazione',
        },
        {
            text: 'Dress Code',
            href: '#dress-code',
        },
        {
            text: 'Dediche',
            href: '#dediche',
        },
        {
            text: 'Playlist',
            href: '#playlist',
        },
        {
            text: 'Lista Nozze',
            href: '#lista-nozze',
        },
    ]

    window.onscroll = function() {
        var scrollPosition = window.scrollY || document.documentElement.scrollTop;
        const header = document.getElementById('header');

        if (scrollPosition > 84) {
            if (!header.classList.contains("scrolled"))
                header.classList.add("scrolled", "collapsed");
        } else {
            header.classList.remove("scrolled", "collapsed");
        }
    };

    const ExpandHeader = () => {
        const header = document.getElementById('header');
        header.classList.remove("collapsed");
    }

    const CollapseHeader = () => {
        const header = document.getElementById('header');
        header.classList.add("collapsed");
    }

    const ScrollToSection = (href) => {
        // if (['#dediche'].includes(href)){
        //     Swal.fire({
        //         toast: true,
        //         position: "top",
        //         titleText: "Disponibile a breve!",
        //         icon: "warning",
        //         target: 'body',
        //         // backdrop: false,
        //         iconColor: '#FFAE42',
        //         color: '#000000',
        //         background: '#FFFFFF',
        //         timer: 2000,
        //         timerProgressBar: false,
        //         showConfirmButton: false,
        //     });
            
        //     return;
        // }

        const targetY = document.querySelector(href).getBoundingClientRect().top + window.scrollY - 48;

        window.scrollTo({ top: targetY, behavior: 'smooth' });

        CollapseHeader();
    }

  return (
    <header id='header'>
        <IoMenu className='menu-icon' onClick={ExpandHeader}/>
        <IoClose className='close-menu-icon' onClick={CollapseHeader}/>
        {links.map((link, index) => (
            <>
                {index !== 0 &&
                    <Divider />
                }
                <a onClick={() => ScrollToSection(link.href)}>{link.text}</a>
            </>
        ))}
    </header>
  )
}

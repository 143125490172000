import React from 'react'

export default function TutorialSlide({ imgSrc, imgAlt, body, index, currentSlide, noBorder }) {
  return (
    <div id={'tutorial-slide-' + index} className={'tutorial-slide' + (index > currentSlide ? ' next' : (index < currentSlide ? ' previous' : ''))}>
        <img src={imgSrc} alt={imgAlt} className={noBorder ? 'no-border' : ''} />
        <p>{body}</p>
    </div>
  )
}

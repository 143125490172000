import React from 'react'
import Intro from './subSections/intro';
import Colori from './subSections/colori';
import Ispirazione from './subSections/ispirazione';

export default function DressCode() { // TODO: Rivedere tutti i testi

    const LadiesInspirationPics = [
        '/images/dress-code/ladies/collage1.jpg',
        '/images/dress-code/ladies/collage2.jpg',
        '/images/dress-code/ladies/collage3.jpg',
        '/images/dress-code/ladies/collage4.jpg',
        '/images/dress-code/ladies/collage5.jpg',
        '/images/dress-code/ladies/collage6.jpg',
        '/images/dress-code/ladies/collage7.jpg',
        '/images/dress-code/ladies/collage8.jpg',
        '/images/dress-code/ladies/collage9.jpg',
        '/images/dress-code/ladies/collage10.jpg',
        '/images/dress-code/ladies/collage11.jpg',
        '/images/dress-code/ladies/collage12.jpg',
        '/images/dress-code/ladies/collage13.jpg',
        '/images/dress-code/ladies/collage14.jpg',
        '/images/dress-code/ladies/collage15.jpg',
        '/images/dress-code/ladies/collage16.jpg',
        '/images/dress-code/ladies/collage17.jpg',
        '/images/dress-code/ladies/collage18.jpg',
    ];

    const GentlemenInspirationPics = [
        '/images/dress-code/gentlemen/collage1.jpg',
        '/images/dress-code/gentlemen/collage2.jpg',
        '/images/dress-code/gentlemen/collage3.jpg',
        '/images/dress-code/gentlemen/collage4.jpg',
        '/images/dress-code/gentlemen/collage5.jpg',
        '/images/dress-code/gentlemen/collage6.jpg',
        '/images/dress-code/gentlemen/collage7.jpg',
        '/images/dress-code/gentlemen/collage8.jpg',
        '/images/dress-code/gentlemen/collage9.jpg',
        '/images/dress-code/gentlemen/collage10.jpg',
        '/images/dress-code/gentlemen/collage11.jpg',
        '/images/dress-code/gentlemen/collage12.jpg',
        '/images/dress-code/gentlemen/collage13.jpg',
        '/images/dress-code/gentlemen/collage14.jpg',
    ];

    return (
        <section id='dress-code' className='dress-code-section'>
            <Intro />
            <Colori />
            <Ispirazione title={'Ispirazione per le ladies'} pics={LadiesInspirationPics} />
            <Ispirazione title={'Ispirazione per i gentlemen'} pics={GentlemenInspirationPics} />
        </section>
    )
}

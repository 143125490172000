import React from 'react'
import ToAvoidMenIcon from '../../../icons/dressCode/toAvoidMen'
import ToAvoidWomenIcon from '../../../icons/dressCode/toAvoidWomen'

export default function Colori() {
  return (
    <section className='dress-code-colori-section'>
      <h2>I colori del matrimonio</h2>
      <div className='colori'>
        <div className='colore viola' />
        <div className='colore lilla' />
        <div className='colore pesca' />
        <div className='colore giallo' />
        <div className='colore panna' />
        <div className='colore verde' />
      </div>

      <p>Ci farebbe molto piacere se indossaste <strong>una piccola cosa lilla</strong> come il secondo colore qui sopra. Un dettaglio, un gioiello, un paio di scarpe, quello che volete!</p>

      <h6>DA EVITARE</h6>
      <p>
        Vi chiediamo la gentilezza di <strong>NON</strong> vestire di <strong>Bianco o Beige</strong> per gli uomini e <strong>Bianco o Lilla</strong> per le donne poichè sono colori destinati a sposi, testimoni e damigelle.
        <br />Per tutto il resto amiamo i colori, è anche estate, sbizzarritevi!
      </p>

      <div className='da-evitare-icone-div'>
        <ToAvoidMenIcon className='da-evitare-icona'/>
        <ToAvoidWomenIcon className='da-evitare-icona'/>
      </div>
    </section>
  )
}

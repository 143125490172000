import React from 'react'
import { IoClose } from 'react-icons/io5'
import Swal from 'sweetalert2'

export default function IBAN() {

    const CopiaIBAN = (nome) => {
        try {
            if (nome === 'Chiara') {
                navigator.clipboard.writeText('Nome: Chiara Stocchi\nIBAN: IT95I0100565730000000002576')
            } else {
                navigator.clipboard.writeText('Nome: Simone Salsi\nIBAN: IT82B0877012700000000700812')
            }

            //Show success toast
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "Copiato!",
                icon: "success",
                target: 'body',
                // backdrop: false,
                iconColor: '#4B6239',
                color: '#000000',
                background: '#FFFFFF',
                timer: 3000,
                timerProgressBar: false,
                showConfirmButton: false,
            });

        } catch {
            //Show fail toast
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "Impossibile copiare!",
                text: "Toccherà farlo alla vecchia maniera 😕",
                icon: "error",
                target: 'body',
                // backdrop: false,
                iconColor: '#DE6159',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
        }
    }

    const ShowIBAN = () => {
        document.getElementById('IBAN').classList.remove('hide-IBAN');
    }

    const HideIBAN = () => {
        document.getElementById('IBAN').classList.add('hide-IBAN');
    }

  return (
    <div id='IBAN' className='IBAN-container hide-IBAN'>
        <div onClick={ShowIBAN} className='IBAN-overlay'>
            <p>Clicca qui per vedere l'IBAN</p>
        </div>

        <div className='IBAN-data' onClick={() => CopiaIBAN('Simo')}>
            <h5>Simone Salsi</h5>
            <p>IT82B0877012700000000700812</p>
        </div>

        <div className='IBAN-data' onClick={() => CopiaIBAN('Chiara')}>
            <h5>Chiara Stocchi</h5>
            <p>IT95I0100565730000000002576</p>
        </div>

        <p className='helper-text'>(Clicca per copiare! ☝)</p>

        <IoClose className='close-IBAN-icon' onClick={HideIBAN}/>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import Invitato from './invitato';
import ConfirmOverlay from './confirmOverlay';

export default function Partecipazione() {
    const [invitati, setInvitati] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [queryString, setQueryString] = useState('');

    const [invitatoDaConfermare, setInvitatoDaConfermare] = useState(null);

    const HandleQueryStringChange = (e) => {
        setQueryString(e.target.value);
    }

    const FetchInvitati = async () => {
        await fetch('https://api.matrimonio.laimbranding.it/invitati',
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
        })
        .then(res => res.json())
        .then(res => {
            if (res.success) setInvitati(res.invitati);
        }).catch(err => {})
    }

    useEffect(() => {
        FetchInvitati();
    }, [])

    useEffect(() => {
        if (invitati === null) return;

        if (queryString.trim() === '') {
            setSearchResults([]);
            return;
        }

        setSearchResults(
            invitati
            .filter(
                invitato =>
                `${invitato.nome} ${invitato.cognome}`.toLowerCase().includes(queryString.replace(/\s+/g, ' ').trim().toLowerCase())
                ||
                `${invitato.cognome} ${invitato.nome}`.toLowerCase().includes(queryString.replace(/\s+/g, ' ').trim().toLowerCase())
                ||
                invitato.soprannome.toLowerCase().includes(queryString.replace(/\s+/g, ' ').trim().toLowerCase())
            ).slice(0, 3)
        )

    }, [queryString])

    useEffect(() => {
        setQueryString('');
    }, [invitati])

    useEffect(() => {
        if (invitatoDaConfermare !== null) { // means ConfirmOverlay is open
            document.body.style.overflowY = 'hidden';
            document.body.style.touchAction = 'none';
        } else {
            document.body.style.overflowY = 'auto';
            document.body.style.touchAction = 'auto';
        }
    }, [invitatoDaConfermare])



    return (
        <>
            <section id='partecipazione' className='partecipazione-section'>
                <h2>Parteciperai al nostro grande giorno<span>?</span></h2>
                <p className='subtitle'>
                    Inizia a scrivere il tuo nome (o quello dei tuoi familiari) per cercarti tra la lista degli invitati e, quando ti vedi, clicca “Ci sarò”!
                </p>

                {invitati === null &&
                    <div className='interactive-div'>
                        <p className='helper-text'>Recuperando la lista degli invitati...</p>
                    </div>
                }

                {invitati !== null && invitati.length <= 0 &&
                    <div className='interactive-div'>
                        <p className='helper-text'>
                            La lista degli invitati sembra vuota...
                            <br />
                            Deve esserci un errore 🤔
                            <br />
                            <br />
                            Ci manderesti un messaggio per avvisarci?
                            <br />
                            Trovi i nostri numeri poco più su! ☝
                            <br />
                            Grazie mille!
                        </p>
                    </div>
                }

                {invitati !== null && invitati.length > 0 &&
                    <div className='interactive-div'>
                        <div className='input-wrapper'>
                            <input placeholder='Il tuo nome' name='nome' type='text' value={queryString} onChange={HandleQueryStringChange}/>
                                <p className={invitati.filter(invitato => invitato.presenza !== null).length < 40 ? 'hidden' : ''}>{invitati.filter(invitato => invitato.presenza !== null).length}/{invitati.length} partecipanti hanno già confermato! 😍</p>
                        </div>


                        {searchResults.length <= 0 ?
                            <div className='search-results-div'>
                                {queryString.trim() === '' ?
                                    <div className='search-helper-text-wrapper'>
                                        <p className='search-helper-text'>Inizia a scrivere per vedere risultati! 🧐</p>
                                    </div>
                                    :
                                    <div className='search-helper-text-wrapper'>
                                        <p className='search-helper-text'>Nessun invitato trovato! 😕</p>
                                    </div>
                                }
                            </div>
                            :
                            <div className='search-results-div'>
                                {searchResults.map(invitato => <Invitato key={invitato.id} invitato={invitato} FetchInvitati={FetchInvitati} setInvitatoDaConfermare={setInvitatoDaConfermare} />)}
                            </div>
                        }

                    </div>

                }
            </section>
        
            {invitatoDaConfermare !== null &&
                <ConfirmOverlay invitato={invitatoDaConfermare} setInvitatoDaConfermare={setInvitatoDaConfermare} />
            }
        </>
    )
}

import React from 'react'

export default function CardRegalo({ nomeRegalo, link, img, setSelectedItem, OpenOverlay }) {

  const linkSeparator = '---';
  const overlayURL = 'https://matrimonio.laimbranding.it/?overlay-';

  const hasMultipleLinks = link.includes(linkSeparator);
  const isOverlayURL = (url) => url.includes(overlayURL);

  return (
    <div class='card-regalo'>
      <img src={'https://api.matrimonio.laimbranding.it/' + img} />
      <h4>{nomeRegalo.replace('\\n', '\n')}</h4>
      {hasMultipleLinks ?
        <div className='link-container'>
          {link.split(linkSeparator).map((splitLink, index) => (
            <a href={isOverlayURL(splitLink) ? null : splitLink} onClick={isOverlayURL(splitLink) ? () => OpenOverlay(link) : null} target='_blank'>Link {index + 1} </a>
          ))}
        </div>
        :
        <a href={isOverlayURL(link) ? null : link} onClick={isOverlayURL(link) ? () => OpenOverlay(link) : null} target='_blank'>Link di riferimento</a>
      }
      <button onClick={() => setSelectedItem(nomeRegalo)}>Ci penso me!</button>
    </div>
  )
}

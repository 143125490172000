import React, { useState } from 'react';
import { FaCameraRetro } from "react-icons/fa";
import { IoClose } from 'react-icons/io5';
import Swal from 'sweetalert2';

export default function Dediche() {
    const [photo, setPhoto] = useState(null);

    const HandlePhotoInputChange = (e) => {
        setPhoto(e.target.files[0] ?? null);
    }

    const ResetPhotoInput = () => {
        document.getElementById("photo").value = "";
        setPhoto(null);
    }

    const HandleSubmitPhoto = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('message', e.target.elements.message.value);
        formData.append('signature', e.target.elements.signature.value);
        formData.append('photo', e.target.elements.photo.files[0]);
        
        await fetch('https://api.matrimonio.laimbranding.it/photoDedication',
        {
            method: 'POST',
            body: formData,
            headers: {
                // 'Content-type': 'multipart/form-data',
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
        })
        .then(res => res.json())
        .then(res => {
            if (res.success) {
                //Show success toast
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "Dedica inviata! 💜",
                    icon: "success",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#4B6239',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });

                //Reset form
                e.target.reset();
                setPhoto(null);
            } else {
                //Show fail toast
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "C'è stato un errore! 😕",
                    text: "Riprova o scrivici!",
                    icon: "error",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#DE6159',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
            }
        })
        .catch(err => {
            //Show fail toast
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "C'è stato un errore! 😕",
                text: "Se non va ancora, scrivici!",
                icon: "error",
                target: 'body',
                // backdrop: false,
                iconColor: '#DE6159',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
        })
    }

    const HandleSubmitSong = async (e) => {
        e.preventDefault();

        await fetch('https://api.matrimonio.laimbranding.it/songDedication',
        {
            method: 'POST',
            body: JSON.stringify({
                song: e.target.elements.song.value,
                message: e.target.elements.message.value ?? null,
                signature: e.target.elements.signature.value,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
        })
        .then(res => res.json())
        .then(res => {
            if (res.success) {
                //Show success toast
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "Dedica inviata! 💜",
                    icon: "success",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#4B6239',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });

                //Reset form
                e.target.reset();
            } else {
                //Show fail toast
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "C'è stato un errore! 😕",
                    text: "Riprova o scrivici!",
                    icon: "error",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#DE6159',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
            }
        })
        .catch(err => {
            //Show fail toast
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "C'è stato un errore! 😕",
                text: "Se non va ancora, scrivici!",
                icon: "error",
                target: 'body',
                // backdrop: false,
                iconColor: '#DE6159',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
        })
    }

    return (
        <section id='dediche' className='dediche-section'>
            <section className='dediche-photo-section'>
                <h2>Dediche</h2>
                <p>
                    Saremmo felici se ci lasciassi un messaggio 😊
                    <br/><br/>
                    Che sia un pensiero che vuoi condividere, una dedica da conservare nel tempo o una foto per ricordare un momento passato insieme 💜
                </p>
                <form onSubmit={HandleSubmitPhoto}>
                    <textarea
                        required={true}
                        id='photo-message'
                        name='message'
                        placeholder="Un messaggio dal cuore ♡"
                        rows={6}
                    />
                    <div className='img-upload-div'>
                        <input type='file' accept="image/png, image/jpeg" id='photo' name='photo' onChange={HandlePhotoInputChange} />
                        <FaCameraRetro className='photo-upload-icon' />
                        <div className='photo-name-div'>
                            <p>{photo?.name ?? 'Nessuna foto selezionata'}</p>
                            {photo?.name &&
                                <IoClose className='remove-photo-icon' onClick={ResetPhotoInput}/>
                            }
                        </div>
                        <label for='photo'>Carica foto</label>
                    </div>
                    <div className='signature-div'>
                        <p className='from'>Da:</p>
                        <input
                            required={true}
                            id='photo-signature'
                            name='signature'
                            placeholder='Il tuo nome'
                        />
                        <button type='submit'>Invia!</button>
                    </div>
                </form>
            </section>

            <section className='dediche-song-section'>
                <h2>Dediche Musicali</h2>
                <p>
                    Hai una canzone (dolce o stupida che sia) che vuoi dedicarci?
                    <br/><br/>
                    Inseriscila qui sotto e il DJ la metterà durante la cena 😊
                </p>
                <form onSubmit={HandleSubmitSong}>
                    <input
                        required={true}
                        id='song'
                        name='song'
                        placeholder='La mia canzone per voi'
                    />
                    <textarea
                        id='song-message'
                        name='message'
                        placeholder="Un piccolo messaggio, se vuoi ♡"
                        rows={6}
                    />
                    <div className='signature-div'>
                        <p className='from'>Da:</p>
                        <input 
                            required={true}
                            id='song-signature'
                            name='signature'
                            placeholder='Il tuo nome'
                        />
                        <button type='submit'>Invia!</button>
                    </div>
                </form>
            </section>
        </section>
    )
}

import { useEffect, useState } from 'react';
import Footer from './components/footer';
import Header from './components/header';
import ATF from './sections/atf';
import Info from './sections/info';
import ListaNozze from './sections/listaNozze';
import './styles.css';
import Overlay from './sections/overlay';
import DressCode from './sections/dressCode';
import Partecipazione from './sections/partecipazione';
import Playlist from './sections/playlist';
import Dediche from './sections/dediche';

function App() {
  const [overlay, setOverlay] = useState({
    id: null,
    prezzo: null,
  });
  const ignoreOverlay = sessionStorage.getItem('Ignore Overlay') === 'true';
  const allowedOverlayIDs = [1, 2]; // 1 --> Regalo già preso; 2 --> Regalo da padre Chiara

  const OpenOverlay = (link) => {
    const overlayLink = link ?? window.location.search;

    const searchSeparator = '--';
    const prezzo = overlayLink.split(searchSeparator)[1];

    if (overlayLink.includes('?overlay-1')){
      document.body.style.overflowY = 'hidden';
      document.body.style.touchAction = 'none';
      setOverlay({
        id: 1,
        prezzo
      });
    }

    if (overlayLink.includes('?overlay-2')){
      document.body.style.overflowY = 'hidden';
      document.body.style.touchAction = 'none';
      setOverlay({
        id: 2,
        prezzo
      });
    }
  }

  const CloseOverlay = () => {
    sessionStorage.setItem('Ignore Overlay', 'true');
    document.body.style.overflowY = 'auto';
    document.body.style.touchAction = 'auto';
    setOverlay({
      id: null,
      prezzo: null,
    });
  }

  useEffect(() => {
    if (!ignoreOverlay) OpenOverlay();
  }, [])
  
  return (
    <>
    <Header />
    <main>
      <div className='header-background' />
      <ATF />
      <Info />
      <Partecipazione />
      <DressCode />
      <Dediche />
      <Playlist />
      <ListaNozze OpenOverlay={OpenOverlay} />
      {/* Sezione per ispirazione per foto da fare il giorno del matrimonio con famiglia e amici? */}

      {allowedOverlayIDs.includes(overlay.id) &&
        <Overlay allowedOverlayIDs={allowedOverlayIDs} overlayData={overlay} CloseOverlay={CloseOverlay} />
      }
    </main>
    <Footer />
    </>
  );
}

export default App;

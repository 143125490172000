import React from 'react'
import ThumbsUpIcon from '../../../icons/dressCode/thumbsUp'
import ThumbsDownIcon from '../../../icons/dressCode/thumbsDown'

export default function Intro() {
  return (
    <section className='dress-code-intro-section'>
        <h2>Ma cosa mi metto<span>?</span></h2>
        <p>È Giugno e finalmente inizia l'estate! Via a tutti i colori da cocktail, freschi e frizzanti.</p>

        <div className='consigli-container'>
            <div className='consigli'>
                <ThumbsUpIcon className='consigli-icon' />
                <ul>
                    <li>Sandali gioiello</li>
                    <li>Oro, argento e bronzo</li>
                    <li>Tacchi bassi o molto larghi</li>
                    <li>Vestiti colorati</li>
                    <li>Tinte accese</li>
                </ul>
            </div>

            <div className='consigli'>
                <ThumbsDownIcon className='consigli-icon' />
                <ul>
                    <li>Tacchi a spillo</li>
                    <li>Vestiti neri</li>
                    <li>Vestiti troppo noiosi</li>
                </ul>
            </div>
        </div>
    </section>
  )
}

import React from 'react';
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";


export default function Ispirazione({ title, pics }) {
  const ref = useRef();
  const { events } = useDraggable(ref);

  return (
    <section className='dress-code-ispirazione-section'>
      <h2>{title}</h2>
      <p>Scorri verso destra per <span>vedere di più!</span></p>

      <div className='pics-scrolling-wrapper' ref={ref} {...events}>
        <div className='pics-grid'>
          {pics.map(picSrc => (
            <img src={picSrc}/>
          ))}
        </div>
      </div>

    </section>
  )
}

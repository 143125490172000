import React, { useState } from 'react'
import { IoClose } from "react-icons/io5"
import TutorialSlide from './tutorialSlide'

export default function TutorialOverlay({ CloseTutorialOverlay }) {

  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      imgSrc: '/images/lista-nozze/tutorial-slide-1.gif',
      imgAlt: 'Step 1',
      body: 'Scorri la lista e trova un regalo che ti ispiri!',
    },
    {
      imgSrc: '/images/lista-nozze/tutorial-slide-2.gif',
      imgAlt: 'Step 2',
      body: 'Clicca il "Link di riferimento" per capire dove comprarlo, quanto costa e vedere tutti i dettagli',
    },
    {
      imgSrc: '/images/lista-nozze/tutorial-slide-3.gif',
      imgAlt: 'Step 3',
      body: 'Quando hai deciso, clicca "Ci penso me!"',
    },
    {
      imgSrc: '/images/lista-nozze/tutorial-slide-4.gif',
      imgAlt: 'Step 4',
      body: 'Riceverai una mail di promemoria con tutte le info utili (link per l\'acquisto, indirizzo a cui spedire, etc...)',
    },
    {
      imgSrc: '/images/lista-nozze/tutorial-slide-5.gif',
      imgAlt: 'Step 5',
      body: 'Fai l\'acquisto normalmente ed è fatta! 💜',
      noBorder: true,
    },
  ]

  // const ShowNextSlide = () => {
  //   if (currentSlide < slides.length - 1) {
  //     setCurrentSlide(currentSlide + 1);
  //   }
  // }

  // const ShowPreviousSlide = () => {
  //   if (currentSlide > 0) {
  //     setCurrentSlide(currentSlide - 1);
  //   }
  // }

  const HandleNextSlideLinkCliked = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      CloseTutorialOverlay();
    }
  }

  return (
    <div className='lista-nozze-tutorial-container'>
        <div className='lista-nozze-tutorial-inner'>
          <IoClose className='lista-nozze-tutorial-close-btn' onClick={CloseTutorialOverlay}/>
          {/* <IoChevronBack className={'lista-nozze-tutorial-back-btn' + (currentSlide <= 0 ? ' disabled' : '')} onClick={ShowPreviousSlide}/>
          <IoChevronForward className={'lista-nozze-tutorial-fwd-btn' + (currentSlide >= (slides.length - 1) ? ' disabled' : '')} onClick={ShowNextSlide}/> */}
          <p className='slide-counter-text'>{currentSlide + 1} / {slides.length}</p>
          <p className='next-slide-link' onClick={HandleNextSlideLinkCliked}>{currentSlide >= slides.length - 1 ? 'Tutto chiaro!' : 'Avanti'}</p>

          {slides.map((slide, index) => (
            <TutorialSlide {...slide} index={index} currentSlide={currentSlide} totalSlides={slides.length} />
          ))}

        </div>
    </div>
  )
}

import React from 'react'

export default function FullInfo() {
  return (
    <section className='info-full-info-section'>
      <h2>Le info al volo</h2>
      <div className='full-info-content'>
        <div className='text-div'>
          <address>
              <strong>Data:</strong> Venerdì 14 Giugno 2024
              <br/>
              <strong>Orario:</strong> Dalle 17:00
              <br/>
              <strong>Location:</strong> Antica Corte Ortalli (<a href='https://maps.app.goo.gl/WkRNXKYKRZ6f2mYa9' rel="noopener noreferrer nofollow" target='_blank'>Apri in Google Maps</a>)
              <br/>
              <strong>Indirizzo:</strong> Via Emilia, Sant'Ilario d'Enza Gattatico Provincia di Reggio Emilia IT, Ponte Enza, 29, 42043 Loc, RE
          </address>

          <address>
              <strong>Per qualunque domanda:</strong>
              <br/>
              Sposa: 348 2118024
              <br/>
              Sposo: 320 7882674
          </address>
        </div>

        <iframe title='Antica Corte Ortalli' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.608054857288!2d10.4390836!3d44.7684076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4780169e4135d54f%3A0x1751ff4267932c1c!2sAntica%20Corte%20Ortalli!5e0!3m2!1sen!2sit!4v1711833631389!5m2!1sit!2sen" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
        </div>
      </section>
  )
}

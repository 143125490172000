import React from 'react'
import RingsIcon from '../../../icons/info/ringsIcon'

export default function Benvenuti() {
  return (
    <section className='info-benvenuti-section'>
        <h2>Benvenuti al nostro matrimonio</h2>
        <p>
            Siamo felicissimi di avervi, non stiamo più nella pelle!
            <br/>
            Stiamo organizzando questo matrimonio e vogliamo che sia un giorno speciale per tutti.
            <br/><br/>
            In attesa del grande giorno, su questo sito puoi trovare tutte le info che servono, confermare la tua partecipazione, fare una dedica o condividere canzoni che non possono mancare.
            <br/><br/>
            Goditi il sito e ci vediamo presto! Un abbraccione!
        </p>
        <RingsIcon className='rings-icon' />
    </section>
  )
}

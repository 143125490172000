import React, { useEffect, useState } from 'react';
import { Puff } from 'react-loading-icons';
import Swal from 'sweetalert2';
import { FaSpotify } from 'react-icons/fa6';
import { AiOutlinePlusCircle, AiFillCheckCircle } from "react-icons/ai";

export default function Playlist() {
    const [suggestionsPlaylist, setSuggestionsPlaylist] = useState([]);
    const [suggestionsDB, setSuggestionsDB] = useState([]);
    const [songQueryString, setSongQueryString] = useState('');
    const [dbSuggestion, setDbSuggestion] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchTimeoutID, setSearchTimeoutID] = useState(null);

    const HandleSongQueryStringChange = (e) => {
        setSongQueryString(e.target.value);

        // On any change, clear the timeout
        clearTimeout(searchTimeoutID);

        if (e.target.value === '') {
            setSearchResults([]);
            setSearchTimeoutID(null);
            return;
        }

        const newTimeoutID = setTimeout(() => {
            fetch('https://api.matrimonio.laimbranding.it/searchSpotify?song=' + e.target.value, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8',
                },
            })
            .then(res => res.json())
            .then(json => {
                setSearchTimeoutID(null);
                if (json.error) return;
                setSearchResults(json.searchResults);
            })
            .catch(() => {});
        }, 500)

        setSearchTimeoutID(newTimeoutID);
    }

    const AddSongToPlaylist = (uri) => {
        if (!uri) return;

        fetch('https://api.matrimonio.laimbranding.it/suggestionsPlaylist',
        {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
            body: JSON.stringify({
                uris: [uri]
            })
        })
        .then(res => res.json())
        .then(res => {
            if (res.error) {
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "Qualcosa è andato storto! 😕",
                    text: "Ricarica la pagina e riprova.",
                    icon: "error",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#DE6159',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
                return;
            }
            
            // Fetch updated suggestionsPlaylist
            FetchSuggestionsPlaylist();

            Swal.fire({
                toast: true,
                position: "top",
                titleText: "Canzone aggiunta! 🎉",
                icon: "success",
                target: 'body',
                // backdrop: false,
                iconColor: '#4B6239',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
            
        }).catch(() => {
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "Qualcosa è andato storto! 😕",
                text: "Ricarica la pagina e riprova.",
                icon: "error",
                target: 'body',
                // backdrop: false,
                iconColor: '#DE6159',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
        })
    }

    const AddDBSuggestion = () => {
        if (!dbSuggestion) return;

        fetch('https://api.matrimonio.laimbranding.it/suggestionsDB',
        {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
            body: JSON.stringify({
                name: dbSuggestion
            })
        })
        .then(res => res.json())
        .then(res => {
            if (res.error) {
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "Qualcosa è andato storto! 😕",
                    text: "Ricarica la pagina e riprova.",
                    icon: "error",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#DE6159',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
                return;
            }
            
            // Fetch updated suggestionsDB
            FetchSuggestionsDB();
            setDbSuggestion('');

            Swal.fire({
                toast: true,
                position: "top",
                titleText: "Canzone aggiunta! 🎉",
                icon: "success",
                target: 'body',
                // backdrop: false,
                iconColor: '#4B6239',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
            
        }).catch(() => {
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "Qualcosa è andato storto! 😕",
                text: "Ricarica la pagina e riprova.",
                icon: "error",
                target: 'body',
                // backdrop: false,
                iconColor: '#DE6159',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
        })
    }

    const HandleDBSuggestionSubmit = (e) => {
        e.preventDefault();
        AddDBSuggestion();
    }

    const FetchSuggestionsPlaylist = () => {
        fetch('https://api.matrimonio.laimbranding.it/suggestionsPlaylist',
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
        })
        .then(res => res.json())
        .then(res => {
            if (res.error) return;
            setSuggestionsPlaylist(res.suggestions);
        }).catch(err => {})
    }

    const FetchSuggestionsDB = () => {
        fetch('https://api.matrimonio.laimbranding.it/suggestionsDB',
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
        })
        .then(res => res.json())
        .then(res => {
            if (res.error) return;
            setSuggestionsDB(res.suggestionsDB);
        }).catch(err => {})
    }

    useEffect(() => {
        FetchSuggestionsPlaylist();
        FetchSuggestionsDB();
    }, [])

    return (
        <section id='playlist' className='playlist-section'>
            <h2>La Playlist</h2>
            <p>
                Vogliamo che tutti si divertano! 😊
                <br />
                Quindi se hai canzoni che non <span>possono mancare,</span>
                <br />
                scrivicele qui 👇
            </p>

            <div className='interactive-div'>
                <div className='spotify-div'>
                    <h3>Aggiungi alla Playlist Spotify</h3>
                    <input type='text' name='song' placeholder='Ricerca su Spotify' value={songQueryString} onChange={HandleSongQueryStringChange} />
                    <div className={searchTimeoutID === null && searchResults.length > 0 ? 'search-results-div showing-results' : 'search-results-div'}>
                        {searchTimeoutID !== null && // Searching
                            <Puff stroke='darkgreen' className='loading-icon' />
                        }

                        {searchTimeoutID === null && songQueryString === '' && searchResults.length <= 0 && //Default state
                            <p>Inizia a scrivere per vedere risultati!</p>
                        }

                        {searchTimeoutID === null && songQueryString !== '' && searchResults.length <= 0 && //No results
                            <p>Nessun risultato 😕</p>
                        }

                        {searchTimeoutID === null && searchResults.length > 0 && //Show results
                            <ul>
                                {[...new Set(searchResults)].slice(0, 5).map(song => {
                                    const songAlreadyInPlaylist = suggestionsPlaylist.map(suggestion => suggestion.id).includes(song.id);

                                    return (
                                        <li>
                                            <img src={song.imgSrc} alt='' />
                                            <div className='song-title-div'>
                                                <p>{song.title}</p>
                                                <p className='song-artist'>{song.artist}</p>
                                            </div>
                                            {songAlreadyInPlaylist ?
                                                <AiFillCheckCircle className='action-icon check-icon' />
                                                :
                                                <AiOutlinePlusCircle onClick={() => AddSongToPlaylist(song.uri)} className='action-icon add-icon' />
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </div>
                </div>

                <div className='db-div'>
                    <h3>Consigli matti (non su Spotify)</h3>
                    <form onSubmit={HandleDBSuggestionSubmit}>
                        <input type='text' name='dbSuggestion' placeholder='Una canzone trash' value={dbSuggestion} onChange={(e) => setDbSuggestion(e.target.value)} />
                        <AiOutlinePlusCircle className={dbSuggestion ? 'add-icon' : 'add-icon disabled'} onClick={AddDBSuggestion} />
                    </form>
                    <h4>Tutti i consigli finora 😬</h4>
                    <div className='scrollable-suggestions'>
                        {(suggestionsDB.length <= 0 && suggestionsPlaylist.length <= 0) ?
                            <p>
                                Ancora nessun consiglio 🤷‍♂️
                                <br/><br/>
                                Aggiungi tu la prima canzone!
                            </p>
                            :
                            <ul>
                                {suggestionsPlaylist.map(suggestion => (
                                    <li>
                                        <p>{suggestion.title + ' - ' + suggestion.artist}</p>
                                        <FaSpotify className='spotify-icon' />
                                    </li>
                                ))}

                                {suggestionsDB.map(suggestion => (
                                    <li>
                                        <p className='db-suggestion'>{suggestion.name}</p>
                                    </li>
                                ))}
                            </ul>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

import React from 'react'

export default function Consigli() {
  return (
    <section className='info-consigli-section'>
      <h2>Due consigli</h2>
      <div className='consiglio-div'>
        <img src='/images/info/consigli-tacchi.jpg' alt='No tacchi' />
        <p>
          Cerimonia e aperitivo saranno in un prato, mentre la cena in un ciottolato antico.
          <br/>
          <strong>Sconsigliamo</strong> perciò i tacchi a spillo o i <strong>tacchi troppo alti</strong>.
          <br/>
          Ci teniamo alle vostre caviglie ;)
        </p>
      </div>
      <div className='consiglio-div'>
        <img src='/images/info/consigli-giacca.jpg' alt='Giacchino' />
        <p>
          Essendo la location una zona abbastanza aperta, potrebbe esserci <strong>fresco la sera</strong>.
          <br/>
          Ricordate un coprispalla o giacchino!
        </p>
      </div>
      <div className='consiglio-div'>
        <img src='/images/info/consigli-zanzara.jpg' alt='Anti-zanzare' />
        <p>
          Sarà fatto il trattamento anti-zanzare, ma essendo immersi nel verde non si sa mai.
          <br/>
          Per evitare di ballare la samba alla cerimonia consigliamo uno <strong>spray anti-zanzare</strong>.
        </p>
      </div>
    </section>
  )
}

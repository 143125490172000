import React from 'react'

export default function Footer() {
  return (
    <footer>
      <picture>
        <source media="(min-width:125rem)" srcset="/images/footer/footer4000.jpg" />
        <source media="(max-width:125rem)" srcset="/images/footer/footer3000.jpg" />
        <source media="(max-width:64rem)" srcset="/images/footer/footer2000.jpg" />
        <img src="/images/footer/footer3000.jpg" />
      </picture>
      <div className='footer-text'>
        <p>Chiara e Simo<br/>14 Giugno 2024</p>
        <p>Sito creato con amore</p>
      </div>
    </footer>
  )
}

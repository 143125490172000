import React from 'react'

const Overlay1 = ({ CloseOverlay, prezzo }) => ( // Overlay regalo già preso --> Tavolino AABERNAA
    <div className='gift-overlay'>
        <div className='gift-overlay-inner'>
            <h2>Questo regalo è un po' speciale 😬</h2>
            <p>
                In breve.
                <br/><br/>
                Abbiamo notato poco fa che era <i><b>l'ultimo pezzo</b></i> e quindi...
                <br/><br/>
                ...l'abbiamo preso 👀
                <br/><br/>
                Se ti va comunque di "regalarcelo" il prezzo è stato di {prezzo}€ 🙏
                <br/><br/><br/>
                Ti basterà cliccare "Ci penso me!" normalmente, inserire la tua email e ci faremo sentire noi!
                <br/>
                Grazie! 💜
            </p>
            
            <a onClick={CloseOverlay}>Torna al Sito</a>
        </div>
    </div>
)

const Overlay2 = ({ CloseOverlay, prezzo }) => ( // Overlay regalo padre Chiara
    <div className='gift-overlay'>
        <div className='gift-overlay-inner'>
            <h2>Questo regalo è un po' speciale 😬</h2>
            <p>
                Nel caso non lo sapessi: il papà di Chiara ha un negozio di elettrodomestici.
                <br/><br/>
                Per noi è una sicurezza poterlo chiamare nel caso ci sia qualcosa che non va.
                <br/><br/>
                Quindi per questo regalo puoi sentire direttamente lui al <a href='tel:+393388460659'>338 8460659</a>.
                {prezzo && 
                    <>
                    <br/><br/>
                    Per farti un'idea già ora, il prezzo è di {prezzo}€ 🙏
                    </>
                }
                <br/><br/><br/>
                Per confermare ti basterà cliccare "Ci penso me!" normalmente!
                <br/>
                Grazie! 💜
            </p>
            
            <a onClick={CloseOverlay}>Torna al Sito</a>
        </div>
    </div>
)

export default function Overlay({ allowedOverlayIDs, overlayData, CloseOverlay }) {

    if (!allowedOverlayIDs.includes(overlayData.id)) return null;

    switch (overlayData.id) {
        case 1:
            return <Overlay1 CloseOverlay={CloseOverlay} prezzo={overlayData.prezzo}/>

        case 2:
            return <Overlay2 CloseOverlay={CloseOverlay} prezzo={overlayData.prezzo}/>
    
        default:
            return null;
    }
}

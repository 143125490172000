import React from 'react';
import Swal from 'sweetalert2';

export default function Invitato({ invitato, FetchInvitati, setInvitatoDaConfermare }) {

    const { id, nome, cognome, presenza } = invitato;

    const showChangedYourMindToast = () => {
        Swal.fire({
            toast: true,
            position: "top",
            titleText: "Cambiato idea? Chiamaci!",
            icon: "warning",
            target: 'body',
            // backdrop: false,
            iconColor: '#FFAE42',
            color: '#000000',
            background: '#FFFFFF',
            timer: 3000,
            timerProgressBar: false,
            showConfirmButton: false,
        });
    }

    const showDeclineConfirm = async() => {
        //Popup di conferma
        const confirmDecline = await Swal.fire({
            titleText: 'Confermi di NON venire?',
            text: 'Mancherai, ma capiamo ♡',
            padding: '1.5rem',
            width: '38rem',
            icon: 'warning',
            iconColor: '#DE6159',
            backdrop: true,
            toast: false,
            target: 'body',
            color: '#010203',
            background: '#FFF',
            position: "center",
            timer: undefined,
            timerProgressBar: false,
            showConfirmButton: true,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla',
            confirmButtonColor: '#DE6159',
            cancelButtonColor: '#FFF',
            focusConfirm: false,
            focusCancel: true,
            // buttonsStyling: false,
            customClass: {
                title: 'swal-popup-title',
                container: 'swal-container',
                htmlContainer: 'swal-popup-subtitle',
                confirmButton: 'swal-popup-btn confirm',
                cancelButton: 'swal-popup-btn cancel'
            }
        })

        if (confirmDecline.isConfirmed) {
            await fetch('https://api.matrimonio.laimbranding.it/invitati',
            {
                method: 'PUT',
                body: JSON.stringify({
                    id,
                    presenza: false,
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
                },
            })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    //Show success toast
                    Swal.fire({
                        toast: true,
                        position: "top",
                        titleText: "Risposta segnata, grazie 🙏",
                        // text: "Riceverai una mail come promemoria tra poco",
                        icon: "success",
                        target: 'body',
                        // backdrop: false,
                        iconColor: '#4B6239',
                        color: '#000000',
                        background: '#FFFFFF',
                        timer: 5000,
                        timerProgressBar: false,
                        showConfirmButton: false,
                    });
                } else {
                    // Show error toast
                    Swal.fire({
                        toast: true,
                        position: "top",
                        titleText: "C'è stato un errore! 😕",
                        text: "Se non va ancora, scrivici!",
                        icon: "error",
                        target: 'body',
                        // backdrop: false,
                        iconColor: '#DE6159',
                        color: '#000000',
                        background: '#FFFFFF',
                        timer: 5000,
                        timerProgressBar: false,
                        showConfirmButton: false,
                    });
                }
            })
            .catch(err => {
                //Show fail toast
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "C'è stato un errore! 😕",
                    text: "Se non va ancora, scrivici!",
                    icon: "error",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#DE6159',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
            })
            .finally(() => {
                FetchInvitati();
            })
        }
    }

    const confirmInvitato = async() => {
        await fetch('https://api.matrimonio.laimbranding.it/invitati',
            {
                method: 'PUT',
                body: JSON.stringify({
                    id,
                    presenza: true,
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
                },
        })
        .then(res => res.json())
        .then(res => {
            if (res.success) {
                setInvitatoDaConfermare({
                    ...invitato,
                    presenza: true,
                });
            } else {
                // Show error toast
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "C'è stato un errore! 😕",
                    text: "Se non va ancora, scrivici!",
                    icon: "error",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#DE6159',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
            }
        })
        .catch(err => {
            //Show fail toast
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "C'è stato un errore! 😕",
                text: "Se non va ancora, scrivici!",
                icon: "error",
                target: 'body',
                // backdrop: false,
                iconColor: '#DE6159',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
        })
        .finally(() => {
            FetchInvitati();
        })
    }

  return (
    <>
        <div className='invitato'>
            <p>{nome} {cognome}</p>

            <div className='buttons-div'>
                {presenza === null &&
                    <>
                        <button onClick={confirmInvitato}>Ci sarò!</button>
                        <button onClick={showDeclineConfirm}>Mancherò :[</button>
                    </>
                }

                {presenza === true &&
                    <button className='disabled' onClick={showChangedYourMindToast}>Presente! Grazie di esserci ♡</button>
                }

                {presenza === false &&
                    <button className='disabled' onClick={showChangedYourMindToast}>Assente. Ci mancherai :[</button>
                }
            </div>
        </div>
    
        {

        }
    </>
  )
}

import React from 'react'

export default function Programma() {
  return (
    <section className='info-programma-section'>
        <h2>Programma</h2>
        <picture>
          <source media='(min-width:57em)' srcset='/images/info/programma-desktop.jpg'/>
          <source media='(max-width:57em)' srcset='/images/info/programma-mobile.jpg'/>
          <img src='/images/info/programma-desktop.jpg' alt='Incontro a Corte Ortalli alle 16:40' />
        </picture>
    </section>
  )
}

import React, { useState } from 'react';
import Swal from 'sweetalert2';

export default function ConfirmOverlay({ invitato, setInvitatoDaConfermare }) {
    const [esigenze, setEsigenze] = useState('');

    const ConfirmInvitato = async(e) => {
        e.preventDefault();

        if (esigenze === '') {
            //Show success toast
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "Risposta segnata, ci vediamo il 14 Giugno! 😍",
                // text: "Riceverai una mail come promemoria tra poco",
                icon: "success",
                target: 'body',
                // backdrop: false,
                iconColor: '#4B6239',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });

            setInvitatoDaConfermare(null);
            return;
        }

        await fetch('https://api.matrimonio.laimbranding.it/invitati',
        {
            method: 'PATCH',
            body: JSON.stringify({
                id: invitato.id,
                esigenze,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
        })
        .then(res => res.json())
        .then(res => {
            if (res.success) {
                //Show success toast
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "Risposta segnata, ci vediamo il 14 Giugno! 😍",
                    icon: "success",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#4B6239',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
            } else {
                // Show error toast
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "Esigenze non segnate! 😕",
                    text: "Abbiamo segnato la presenza, ma non le esigenze. Scrivicele via WhatsApp!",
                    icon: "error",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#DE6159',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
            }
        })
        .catch(err => {
            //Show fail toast
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "C'è stato un errore! 😕",
                text: "Abbiamo segnato la presenza, ma non le esigenze. Scrivicele via WhatsApp!",
                icon: "error",
                target: 'body',
                // backdrop: false,
                iconColor: '#DE6159',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
        })
        .finally(() => {
            setInvitatoDaConfermare(null);
        })
    }

    const handleEsigenzeStringChange = (e) => {
        setEsigenze(e.target.value);
    }

  return (
    <div className='partecipazione-overlay-container'>
        <form className='partecipazione-overlay-inner' onSubmit={ConfirmInvitato}>
            <div className='title-div'>
                <h2>Conferma ricevuta!</h2>
                <p>Grazie di esserci {invitato.soprannome} ♡</p>
            </div>

            <div className='content-div'>
                <h3>Hai esigenze alimentari particolari?</h3>
                <p>Se ti serve il menù per bimbi, sei vegano, hai allergie, intolleranze, diabete o simili, questo è il momento di farcelo sapere!</p>

                <textarea
                    id='esigenze'
                    name='esigenze'
                    placeholder="Dicci tutto"
                    rows={5}
                    value={esigenze}
                    onChange={handleEsigenzeStringChange}
                    style={{ resize: 'none' }} // Disable manual resizing
                />

                <button type='submit'>{esigenze === '' ? 'Non ho esigenze particolari' : 'Segnala le mie esigenze'}</button>
            </div>
        </form>
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import CardRegalo from './cardRegalo'
import IBAN from './IBAN'
import ReactPaginate from 'react-paginate'
import Swal from 'sweetalert2'
import TutorialOverlay from './tutorial/tutorialOverlay'

function ListaNozze_OverlayConferma({ nomeRegalo, setSelectedItem, FetchListaNozze }) {

    const ConfirmGift = async (e) => {
        e.preventDefault();

        const nome = e.target.nome.value;
        const email = e.target.email.value;
        
        await fetch('https://api.matrimonio.laimbranding.it/listaNozze',
        {
            method: 'PATCH',
            body: JSON.stringify({
                nome,
                email,
                nomeRegalo,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
        })
        .then(res => res.json())
        .then(res => {
            if (res.success) {
                //Show success toast
                Swal.fire({
                    toast: true,
                    position: "top",
                    titleText: "Segnato, grazie! 💜",
                    text: "Riceverai una mail come promemoria tra poco",
                    icon: "success",
                    target: 'body',
                    // backdrop: false,
                    iconColor: '#4B6239',
                    color: '#000000',
                    background: '#FFFFFF',
                    timer: 5000,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
            } else {
                switch (res.error) {
                    case 'taken':
                        //Show fail toast (gift already taken)
                        Swal.fire({
                            toast: true,
                            position: "top",
                            titleText: "Oops, già preso! 😕",
                            text: "Qualcun altro ha appena preso questo regalo!",
                            icon: "error",
                            target: 'body',
                            // backdrop: false,
                            iconColor: '#DE6159',
                            color: '#000000',
                            background: '#FFFFFF',
                            timer: 5000,
                            timerProgressBar: false,
                            showConfirmButton: false,
                        });
                        break;

                    case 'email_error':
                        //Show fail toast (couldn't send email)
                        Swal.fire({
                            toast: true,
                            position: "top",
                            titleText: "Segnato, ma...",
                            text: "...l'invio della mail non ha funzionato. Scrivici per farcelo sapere!",
                            icon: "success",
                            target: 'body',
                            // backdrop: false,
                            iconColor: '#FFAE42',
                            color: '#000000',
                            background: '#FFFFFF',
                            timer: 10000,
                            timerProgressBar: false,
                            showConfirmButton: false,
                        });
                        break;
                
                    default:
                        //Show fail toast
                        Swal.fire({
                            toast: true,
                            position: "top",
                            titleText: "C'è stato un errore! 😕",
                            text: "Se non va ancora, scrivici!",
                            icon: "error",
                            target: 'body',
                            // backdrop: false,
                            iconColor: '#DE6159',
                            color: '#000000',
                            background: '#FFFFFF',
                            timer: 5000,
                            timerProgressBar: false,
                            showConfirmButton: false,
                        });
                        break;
                }
            }
        })
        .catch(err => {
            //Show fail toast
            Swal.fire({
                toast: true,
                position: "top",
                titleText: "C'è stato un errore! 😕",
                text: "Se non va ancora, scrivici!",
                icon: "error",
                target: 'body',
                // backdrop: false,
                iconColor: '#DE6159',
                color: '#000000',
                background: '#FFFFFF',
                timer: 5000,
                timerProgressBar: false,
                showConfirmButton: false,
            });
        })
        .finally(() => {
            FetchListaNozze();
        })
    }

    return (
    <div className='lista-nozze-overlay-container'>
        <form className='lista-nozze-overlay-inner' onSubmit={ConfirmGift}>
            <h2>Grazie mille!</h2>
            <p>Chi dovremo ringraziare per <span>{nomeRegalo.replace('\\n', ' ')}? 😊</span></p>
            <input type='text' name='nome' placeholder='Da parte di... (Anche più di una persona!)' maxLength={255} required />

            <p>Dove possiamo mandarti <span>tutte le info?</span><br/>(E un ringraziamento 💜)</p>
            <input type='email' name='email' placeholder='La tua email' pattern="^[\w\-\.]+@([\w\-]+\.)+[\w\-]{2,4}$" title='esempio@gmail.com' maxLength={255} required />

            <button type='submit'>Conferma</button>
            <p className='cancel-text' onClick={() => setSelectedItem(null)}>Torna indietro, <span>ci ho ripensato 😅</span></p>
        </form>
    </div>
    )
}

export default function ListaNozze({ OpenOverlay }) {

    // For testing
    // const setListaNozze = () => {};
    // const listaNozze = [
    //     {
    //         nomeRegalo: 'Bicchieri Diamond Ocean Blue Bormioli\n(3 set da 6pz)',
    //         link: 'https://shop.bormiolirocco.com/bormioli-rocco-diamond-ocean-blue-bicchiere-dof-38-5-cl-set-6-pz-blu.html',
    //         img: '/placeholder-big.jpg',
    //     },
    //     {
    //         nomeRegalo: 'Bicchieri Diamond Ocean Blue Bormioli\n(18pz)',
    //         link: 'https://shop.bormiolirocco.com/bormioli-rocco-diamond-ocean-blue-bicchiere-dof-38-5-cl-set-6-pz-blu.html',
    //         img: '/placeholder-small.jpg',
    //     },
    // ]

    // Dimensione immagini: 525x435 (+ compressione)
    const [listaNozze, setListaNozze] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showTutorial, setShowTutorial] = useState(null);

    useEffect(() => {
      if (selectedItem == null) {
        document.body.style.overflowY = 'auto';
        document.body.style.touchAction = 'auto';
      } else {
        document.body.style.overflowY = 'hidden';
        document.body.style.touchAction = 'none';
      }
    }, [selectedItem])

    useEffect(() => {
      if (selectedItem == null) {
        document.body.style.overflowY = 'auto';
        document.body.style.touchAction = 'auto';
      } else {
        document.body.style.overflowY = 'hidden';
        document.body.style.touchAction = 'none';
      }
    }, [showTutorial])
    

    const FetchListaNozze = async () => {
        await fetch('https://api.matrimonio.laimbranding.it/listaNozze', 
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer 63785ba3d9e0775c81212f5e060bd74c7a3be50869d695e2412394ad048085140d04de541aed4a8e6a59adccdbe9824a6e7394956912cb197d6d80ec1e9148063fa5bd900797796080077127d52c4c5a2b10fdd12e3db0af4768af9896ade4f21ae7df379816dfac8d2b0f4e4690114ed4c07294d33e0a2999ce1d46630554c8', 
            },
        })
        .then(res => res.json())
        .then(res => {
            if (res.success) setListaNozze(res.listaNozze);
        }).catch(err => {})
        .finally(() => setSelectedItem(null))
    }

    useEffect(() => {
        FetchListaNozze();
    }, [])



    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(window.innerWidth > 1400 ? 9 : (window.innerWidth > 912 ? 6 : 3));

    window.onresize = function() {
        if (window.innerWidth > 1400 && itemsPerPage !== 9) setItemsPerPage(9);

        if (1400 >= window.innerWidth && window.innerWidth > 912 && itemsPerPage !== 6) setItemsPerPage(6);

        if (912 >= window.innerWidth && itemsPerPage !== 3) setItemsPerPage(3);
    };


    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = (listaNozze ?? []).slice(itemOffset, endOffset);
    const pageCount = Math.ceil((listaNozze ?? []).length / itemsPerPage);

    const ScrollAfterPageChange = () => {
        const targetY = document.getElementById('cards-container').getBoundingClientRect().top + window.scrollY - 84;

        window.scrollTo({ top: targetY, behavior: 'smooth' });
    }

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % (listaNozze ?? []).length;
        // console.log(
        // `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        setItemOffset(newOffset);

        ScrollAfterPageChange();
    };



    const [currentRange, setCurrentRange] = useState(0);
    const dragScrollElement = useRef(null);

    const handleScroll = () => {
        const scrollableWidth = dragScrollElement.current.scrollWidth - dragScrollElement.current.offsetWidth;
        const amountScrolled = dragScrollElement.current.scrollLeft;
        const rangeValue = (amountScrolled / scrollableWidth) * 100;
        // console.log(scrollableHeight, amountScrolled, rangeValue);
        setCurrentRange(rangeValue);
    };

    const handleRangeInput = (e) => {
        const rangeValue = e.target.value;
        setCurrentRange(rangeValue);
        
        const scrollableWidth = dragScrollElement.current.scrollWidth - dragScrollElement.current.offsetWidth;
        dragScrollElement.current.scrollLeft = (rangeValue / 100) * scrollableWidth;
    }


    
    const ShowTutorialOverlay = () => setShowTutorial(true);
    const CloseTutorialOverlay = () => setShowTutorial(false);

    useEffect(() => {
        document.body.style.overflowY = showTutorial ? 'hidden' : 'auto';
        document.body.style.touchAction = showTutorial ? 'none' :  'auto';
    }, [showTutorial])


  return (
    <>
    <section id='lista-nozze' className='lista-nozze-section'>
        <h2>Lista Nozze</h2>
        <p>
            Il regalo più grande per noi è la tua presenza al nostro giorno, ma se vuoi farci un pensierino lo accettiamo volentieri 😬
            <br/><br/>
            <em onClick={ShowTutorialOverlay}>Come funziona la lista nozze (tutorial)</em>
        </p>

        {listaNozze === null &&
            <div className='regali-finiti'>
                <p>
                    Caricamento...
                </p>
            </div>
        }

        {listaNozze !== null && listaNozze.length > 0 &&
            <div id='cards-container' className='cards-container'>
                <div className='displayed-cards-div-mobile' ref={dragScrollElement} onScroll={handleScroll}>
                    {listaNozze.map(regalo => (
                        <CardRegalo {...regalo} setSelectedItem={setSelectedItem} OpenOverlay={OpenOverlay} />
                    ))}
                </div>

                <input
                    className='scroll-range-input'
                    type="range"
                    value={currentRange}
                    min={0}
                    max={100}
                    onInput={handleRangeInput}
                />

                <p className='lista-nozze-mobile-helper-text'>Scorri verso destra! 👉</p>

                <div className='displayed-cards-div'>
                    {currentItems.map(regalo => (
                        <CardRegalo {...regalo} setSelectedItem={setSelectedItem} OpenOverlay={OpenOverlay} />
                    ))}
                </div>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                />
            </div>
        }

        {listaNozze !== null && listaNozze.length <= 0 &&
            <div className='regali-finiti'>
                <p>
                    Sono già stati presi tutti i regali della lista nozze!
                    <br/>
                    Grazie! 😱💜
                </p>
            </div>
        }

        <div className='info-busta'>
            {(listaNozze ?? []).length > 0 ?
                <p>Niente che ti ispiri? La classica busta per la <strong>Luna di Miele</strong> è sempre ben accetta 😊</p>
                :
                <p>La classica busta per la <strong>Luna di Miele</strong> è sempre ben accetta 😊</p>
            }
                
            <p>(Se preferisci il virtuale qui ci sono i nostri IBAN 👇)</p>
            
            <IBAN />
        </div>
        
    </section>
    {selectedItem !== null &&
        <ListaNozze_OverlayConferma nomeRegalo={selectedItem} setSelectedItem={setSelectedItem} FetchListaNozze={FetchListaNozze} />
    }

    {showTutorial &&
        <TutorialOverlay CloseTutorialOverlay={CloseTutorialOverlay} />
    }
    </>
  )
}
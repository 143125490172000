import React from 'react'
import Benvenuti from './subSections/benvenuti'
import FullInfo from './subSections/fullInfo'
import Programma from './subSections/programma'
import Consigli from './subSections/consigli'

export default function Info() {
  return (
    <section id='info' className='info-section'>
        <Benvenuti />
        <FullInfo />
        <Programma />
        <Consigli />
    </section>
  )
}

import React from 'react'

export default function ToAvoidMenIcon({ className }) {
    return (
        <svg className={className} viewBox="0 0 332 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="166" cy="50" r="49.5" fill="white" stroke="#68451C" />
            <path d="M124 9L208.853 93.8528" stroke="#BD3F3F" stroke-width="4" stroke-linecap="round" />
            <path d="M208.853 9L124 93.8528" stroke="#BD3F3F" stroke-width="4" stroke-linecap="round" />
            <circle cx="282" cy="50" r="49.5" fill="#EDE6CD" stroke="#68451C" />
            <path d="M240 9L324.853 93.8528" stroke="#BD3F3F" stroke-width="4" stroke-linecap="round" />
            <path d="M324.853 9L240 93.8528" stroke="#BD3F3F" stroke-width="4" stroke-linecap="round" />
            <path d="M50 100C77.6154 100 100 77.6154 100 50C100 22.3846 77.6154 0 50 0C22.3846 0 0 22.3846 0 50C0 77.6154 22.3846 100 50 100ZM50 3.84615C75.4462 3.84615 96.1538 24.55 96.1538 50C96.1538 63.7 90.1193 75.9885 80.6077 84.45C77.2808 82.8192 73.8461 81.2885 71.3 80.1923C70.9 80.0269 67.2962 78.5077 67.2962 78.5077C67.2962 78.5077 55.7577 72.6693 61.527 66.8308C63.8577 64.4731 65.2231 60.1923 66.05 55.5577C67.4 55.5231 69.177 52.5846 70.1501 48.65C71.1847 44.4769 70.9077 40.8115 69.5269 40.4692C69.3961 40.4346 69.2576 40.4615 69.123 40.4884C69.4346 37.1192 69.3423 33.9231 68.3807 32.5385C65.3961 28.2693 65.1423 24.5038 60.6192 22.8769C56.1076 21.2423 61.5385 15.3846 61.5385 15.3846C61.5385 15.3846 42.2154 15.927 38.0961 22.8654C35.6769 26.9231 34.5039 28.2615 31.5231 32.5346C30.5616 33.9231 30.5385 37.1231 30.9539 40.4923C30.7769 40.4462 30.6077 40.4269 30.45 40.4692C29.0692 40.8115 28.7923 44.4769 29.8231 48.65C30.8 52.5846 32.5808 55.5231 33.9231 55.5577C34.7539 60.1923 36.1154 64.4693 38.45 66.8308C44.2193 72.6693 32.6808 78.5077 32.6808 78.5077C32.6808 78.5077 29.0769 80.0269 28.6769 80.1923C26.1346 81.2885 22.7039 82.8192 19.3846 84.4423C9.87311 75.9808 3.84615 63.6962 3.84615 50C3.84615 24.55 24.5538 3.84615 50 3.84615Z" fill="black" />
        </svg>
    )
}

import React from 'react'

export default function ATF() {
  return (
    <section className='atf-section'>
        <h1>Chiara e Simo</h1>
        <h4>14 Giugno 2024</h4>
    </section>
  )
}
